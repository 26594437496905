.cookie-info {
    background-color: #222;
    bottom: 20px;
    display: none;
    left: 0;
    margin: 0 auto;
    position: fixed;
    right: 0;
    width: 90%;
    z-index: 1;
}

.cookie-info .icon {
    bottom: 0px;
    color: #D9534F;
    font-size: 26px;
    height: 36px;
    left: 20px;
    margin: auto 0px;
    position: absolute;
    top: 0px;
}

.cookie-info p {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 20px 35px 20px 60px;
    text-align: left;
}

.cookie-info .close-cookie-info {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 12px;
    top: 6px;
}

@media (min-width:768px) {
    .cookie-info {
        bottom: 30px;
        margin: 0 0 0 auto;
        right: 30px;
        width: 320px;
    }
}