body {
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
}

@font-face {
    font-family: "ds-digitalitalic";
    src: url("../fonts/ds-digii-webfont.eot");
    src: url("../fonts/ds-digii-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/ds-digii-webfont.woff2") format("woff2"), url("../fonts/ds-digii-webfont.woff") format("woff"), url("../fonts/ds-digii-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.text-primary {
    color: #47b086;
}

p {
    font-size: 14px;
    line-height: normal;
    margin: 0 0 20px;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: #000
}

a:hover,
a:focus,
a:active,
a.active {
    color: #47b086;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 30px;
    text-transform: uppercase;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
}

h1 {
    font-size: 54px;
    margin: 40px 0 10px;
    text-align: center;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.btn-primary {
    background-color: #47b086;
    border-color: #47b086;
    color: #fff;
    font-family: "Oswald";
}

.btn-lg {
    border-radius: 2px;
    padding: 15px 20px;
}

.btn-secondary {
    background-color: #03C;
    border-color: #03C;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-color: #388c6a;
    border-color: #388c6a;
    color: #fff;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #47b086;
    border-color: #47b086;
}

.btn-primary .badge {
    background-color: #fff;
    color: #47b086;
}

.btn-xl {
    background-color: #47b086;
    border-color: #47b086;
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 40px;
    text-transform: uppercase;
}

.btn-xl1 {
    background-color: #03C;
    border-color: #03C;
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    padding: 20px 40px;
    text-transform: uppercase;
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-color: #47b086;
    border-color: #47b086;
    color: #fff;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    border-color: #47b086;
    background-color: #47b086
}

.btn-xl .badge {
    color: #47b086;
    background-color: #fff
}

.navbar-default {
    border-color: transparent;
    background-color: #222
}

.navbar-default .navbar-brand {
    color: #47b086
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: #47b086;
}

.navbar-default .navbar-collapse {
    border-color: rgba(255, 255, 255, .02)
}

.navbar-default .navbar-toggle {
    border-color: #47b086;
    background-color: #47b086
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #47b086
}

.navbar-default .nav li a {
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: #47b086
}

.navbar-default .navbar-nav>.active>a {
    border-radius: 0;
    color: #fff;
    background-color: #47b086
}

.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #47b086;
}

@media (min-width: 768px) {
    h1 {
        font-size: 72px;
        margin: 60px 0 30px;
    }
    .navbar-default {
        border: 0;
        padding: 25px 0;
        -webkit-transition: padding .3s;
        transition: padding .3s
    }
    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        transition: all .3s;
    }
    .navbar-default .navbar-nav>.active>a {
        border-radius: 2px;
    }
    .navbar-default.navbar-shrink {
        padding: 10px 0;
        background-color: #222
    }
    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em
    }
}

header {
    padding-top: 50px;
}

header .intro-text {
    padding-top: 10px;
    padding-bottom: 5px
}

header .intro-text .intro-lead-in {
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 25px;
}

header .intro-text .intro-heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    header {
        padding-top: 100px;
    }
    header .intro-text {
        padding-top: 200px;
        padding-bottom: 300px
    }
    header .intro-text .intro-lead-in {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 25px;
    }
    header .intro-text .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
}

section h2.section-heading {
    font-size: 32px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    text-align: center;
}

section h2.section-heading::after {
    background-color: #47b086;
    bottom: 0;
    content: "";
    height: 3px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 250px;
}

section p.section-subheading {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    line-height: normal;
    margin-bottom: 30px;
}

.service-heading {
    margin: 15px 0;
    text-transform: none
}

#produkty .produkty-item {
    right: 0;
    margin: 0 0 15px
}

#produkty .produkty-item .produkty-link {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 400px
}

#produkty .produkty-item .produkty-link .produkty-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    background: rgba(254, 209, 54, .9);
    -webkit-transition: all ease .5s;
    transition: all ease .5s
}

#produkty .produkty-item .produkty-link .produkty-hover:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

#produkty .produkty-item .produkty-link .produkty-hover .produkty-hover-content {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    margin-top: -12px;
    text-align: center;
    font-size: 20px;
    color: #fff
}

#produkty .produkty-item .produkty-link .produkty-hover .produkty-hover-content i {
    margin-top: -12px
}

#produkty .produkty-item .produkty-link .produkty-hover .produkty-hover-content h3,
#produkty .produkty-item .produkty-link .produkty-hover .produkty-hover-content h4 {
    margin: 0
}

#produkty .produkty-item .produkty-caption {
    margin: 0 auto;
    padding: 25px;
    max-width: 400px;
    text-align: center;
    background-color: #fff
}

#produkty .produkty-item .produkty-caption h4 {
    margin: 0;
    text-transform: none
}

#produkty .produkty-item .produkty-caption p {
    font-size: 16px;
    font-style: italic;
    margin: 0;
}

#produkty {
    z-index: 2
}

@media(min-width:767px) {
    #produkty .produkty-item {
        margin: 0 0 30px
    }
}

.timeline {
    position: relative;
    padding: 0;
    list-style: none
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #cdcdcd
}

.timeline>li {
    position: relative;
    margin-bottom: 0;
    min-height: 0
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}

.timeline>li:after {
    clear: both
}

.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 0;
    text-align: left
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0
}

.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #cdcdcd;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: #47b086
}

.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0
}

.timeline>li:last-child {
    margin-bottom: 0
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit
}

.timeline .timeline-heading h4.subheading {
    text-transform: none
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0
}

@media(min-width:768px) {
    .timeline:before {
        left: 50%
    }
    .timeline>li {
        margin-bottom: -60px;
        min-height: 100px
    }
    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right
    }
    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px
    }
    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left
    }
}

@media(min-width:992px) {
    .timeline>li {
        min-height: 150px
    }
    .timeline>li .timeline-panel {
        padding: 0 20px 20px
    }
    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px
    }
    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 26px
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px
    }
}

@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px
    }
    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px
    }
    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px
    }
    .timeline>li .timeline-image h4 {
        margin-top: 40px
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px
    }
}

aside.clients img {
    margin: 50px auto
}

section#contact {
    background-color: #222;
    background-image: url(../img/map-image.png);
    background-position: center;
    background-repeat: no-repeat
}

section#contact .section-heading {
    color: #fff
}

section#contact .form-group {
    margin-bottom: 25px
}

section#contact .form-group input,
section#contact .form-group textarea {
    padding: 20px
}

section#contact .form-group input.form-control {
    height: auto
}

section#contact .form-group textarea.form-control {
    height: 236px
}

section#contact .form-control:focus {
    border-color: #47b086;
    box-shadow: none
}

section#contact::-webkit-input-placeholder {
    text-transform: uppercase;
    font-weight: 700;
    color: #bbb
}

section#contact:-moz-placeholder {
    text-transform: uppercase;
    font-weight: 700;
    color: #bbb
}

section#contact::-moz-placeholder {
    text-transform: uppercase;
    font-weight: 700;
    color: #bbb
}

section#contact:-ms-input-placeholder {
    text-transform: uppercase;
    font-weight: 700;
    color: #bbb
}

section#contact .text-danger {
    color: #e74c3c
}

footer {
    background-color: #222;
    padding: 5px 0 20px;
    text-align: center;
}

footer span.copyright {
    color: #fff;
    display: inline-block;
}

footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    line-height: 40px
}

ul.social-buttons {
    margin-bottom: 0
}

ul.social-buttons li a {
    background-color: #47B086;
    border-radius: 100%;
    color: #fff;
    display: block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    outline: 0;
    transition: all 0.3s;
    width: 40px;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    background-color: #388c6a;
}

footer h3 {
    margin: 20px 0;
    text-transform: lowercase;
}

footer h3 .fa {
    color: #47B086;
    font-size: 24px;
    margin-right: 5px;
    vertical-align: middle;
}

footer h3 .fa.fa-mobile {
    font-size: 32px;
}

footer h3 a {
    color: #fff;
    vertical-align: middle;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0
}

.produkty-modal .modal-content {
    background-clip: border-box;
    border-radius: 0;
    border: 0;
    bottom: 0;
    box-shadow: none;
    left: 0;
    margin: auto;
    min-height: 90%;
    overflow: auto;
    padding: 25px 0;
    right: 0;
    text-align: center;
    top: 0;
}

.produkty-modal .modal-content h2 {
    margin-bottom: 15px;
    font-size: 3em
}

.produkty-modal .modal-content p {
    margin-bottom: 30px
}

.produkty-modal .modal-content p.item-intro {
    margin: 20px 0 30px;
    font-size: 16px;
    font-style: italic
}

.produkty-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px
}

.produkty-modal .modal-content img {
    margin-bottom: 30px
}

.produkty-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer
}

.produkty-modal .close-modal:hover {
    opacity: .3;
    filter: alpha(opacity=30)
}

.produkty-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.produkty-modal .close-modal .lr .rl {
    background-color: #222;
    height: 75px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    width: 1px;
    z-index: 1052;
}

.produkty-modal .modal-backdrop {
    display: none;
    opacity: 0;
    filter: alpha(opacity=0)
}

.wrap {
    position: relative;
    clear: none;
    overflow: hidden
}

.wrap .desc {
    display: block;
    position: absolute;
    width: 85%;
    top: 6%;
    left: 10%;
    z-index: 2;
    text-align: center;
    font-size: 70%
}

@media screen and (max-width: 640px) {
    tabela {
        overflow-x: auto;
        display: block
    }
}

.pricing-table {
    box-shadow: 0 0 3px #26292e;
    display: inline-block;
    margin: 30px 10px;
    width: 250px
}

.featured {
    width: 400px;
    height: 250px;
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1)
}

.pricing-table-header {
    background: #65707f;
    background: -webkit-linear-gradient(top, #65707f 0%, #4a5564 100%);
    background: linear-gradient(to bottom, #65707f 0%, #4a5564 100%);
    border-radius: 5px 5px 0 0;
    padding: 16px;
    text-align: center;
}

.pricing-table-header h1 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase
}

.pricing-table-content {
    background-color: #fff
}

.pricing-table-content ul {
    list-style: none;
    margin-top: 0;
    color: #282828;
    padding: 10px
}

.pricing-table-content ul li {
    font-size: 15px;
    color: #282828;
    padding: 5px
}

.pricing-table-footer {
    background-color: #f5f7f8;
    border-radius: 0 0 5px 5px;
    padding: 16px 0;
    text-align: center
}

.pricing-table-footer p {
    font-size: 12px;
    text-transform: uppercase
}

.pricing-table-footer a {
    background: #50b7e4;
    background: -webkit-linear-gradient(top, #50b7e4 0%, #3098c4 100%);
    background: linear-gradient(to bottom, #50b7e4 0%, #3098c4 100%);
    border-radius: 5px;
    border: 1px solid #1481b0;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-top: 10px;
    padding: 10px;
    text-decoration: none;
}

.tlo {
    background-attachment: scroll;
    background-image: url(../img/Fotolia_63161110_Subscription_Monthly_M.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
}

.malaczcionka {
    font-size: 10px
}


/* Smartphone Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .mobileShow {
        display: none;
    }
}

.btn-primary2 {
    background-color: #47b086;
    border-color: #47b086;
    border-radius: 2px;
    color: #fff;
    font-family: "Oswald";
    font-size: 26px;
    padding: 15px 20px;
}

.btn-primary2:hover,
.btn-primary2:focus,
.btn-primary2:active,
.open .dropdown-toggle.btn-primary2 {
    background-color: #388c6a;
    border-color: #388c6a;
    color: #fff;
}

.btn-primary2:active,
.btn-primary2.active,
.open .dropdown-toggle.btn-primary2 {
    background-image: none
}

.btn-primary2.disabled,
.btn-primary2[disabled],
fieldset[disabled] .btn-primary2,
.btn-primary2.disabled:hover,
.btn-primary2[disabled]:hover,
fieldset[disabled] .btn-primary2:hover,
.btn-primary2.disabled:focus,
.btn-primary2[disabled]:focus,
fieldset[disabled] .btn-primary2:focus,
.btn-primary2.disabled:active,
.btn-primary2[disabled]:active,
fieldset[disabled] .btn-primary2:active,
.btn-primary2.disabled.active,
.btn-primary2[disabled].active,
fieldset[disabled] .btn-primary2.active {
    border-color: #47b086;
    background-color: #47b086
}

.btn-primary2 .badge {
    color: #47b086;
    background-color: #fff
}

.margines {
    margin-left: -30px;
}

@media (max-width: 767px) {
    .margines {
        float: left;
        margin-left: 30px;
    }
}

.gora {
    margin-top: 53px;
}


/* Heading */

.navbar-brand {
    padding: 10px 15px;
}

.navbar-brand>img {
    max-height: 100%;
    width: auto;
}

@media (min-width: 768px) {
    .navbar-brand {
        padding: 0 15px;
    }
    .navbar-shrink .navbar-brand {
        padding: 5px 15px;
    }
}


/* End Heading */


/*Main*/

.toxiform .form-group {
    position: relative;
    text-align: center;
}

.toxiform .form-group .controls {
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    width: 100px;
}

.toxiform .form-group .controls.select {
    border-bottom-right-radius: 2px;
    border-right: 1px solid #47b086;
    border-top-right-radius: 2px;
    width: 169px;
}

.toxiform .form-group .controls.select:hover>span {
    background-color: #388c6a;
}

.toxiform .form-group .controls>span {
    background-color: #47b086;
    color: #ffffff;
    cursor: default;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: auto;
}

.toxiform .form-group .controls>span:hover {
    background-color: #388c6a;
}

.toxiform .form-group .controls>.glyphicon-chevron-up {
    border-top-right-radius: 2px;
    bottom: 25px;
}

.toxiform .form-group .controls>.glyphicon-chevron-down {
    border-bottom-right-radius: 2px;
    bottom: 1px;
}

.toxiform .form-group .controls.select>.glyphicon-chevron-down {
    padding: 17px 10px;
    right: -1px;
    z-index: -1;
}

.toxiform .form-group input {
    width: 100px;
}

.toxiform .form-group select {
    padding-right: 85px;
    width: auto;
}

.toxiform .form-group input,
.toxiform .form-group select,
.toxiform img {
    margin-left: auto;
    margin-right: auto;
}

.toxiform .form-group input,
.toxiform .form-group select {
    background-color: rgba(71, 176, 134, 0.2);
    border-radius: 2px;
    border: 1px solid #47B086;
    color: #424242;
    font-size: 16px;
    min-height: 50px;
    padding-left: 23px;
}

.toxiform button[type="reset"] {
    background-color: transparent;
    border: medium none;
    color: #222;
    display: block;
    font-size: 13px;
    margin: 15px auto;
}

.toxiform .check-button-desktop {
    display: none;
}

.toxiform .check-button-desktop {
    margin: 0;
}

.toxiform .check-button-mobile {
    margin: 30px 0 50px;
}

.toxiform .result {
    /*display: none;*/
    margin: 30px 0;
}

.toxiform .result input {
    border-width: 0;
    font-size: 26px;
    text-align: center;
}

.toxiform .incorrect-value {
    background-color: #d9534f;
    border-radius: 2px;
    color: #fff;
    left: 0;
    margin: 0 auto;
    padding: 10px;
    position: absolute;
    right: 0;
    top: -12px;
    width: 245px;
}

.toxiform .incorrect-value::before {
    background-color: #d9534f;
    bottom: -8px;
    content: "";
    height: 15px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    width: 15px;
}

.toxiform .update-value {
    color: #2c2e2d;
    font-family: "ds-digitalitalic";
    font-size: 42px;
    left: -2px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 91px;
    width: 100%;
}

.toxiform .update-value::after {
    border-radius: 50%;
    border: 4px solid #d9534f;
    box-shadow: 0 0 0 70px rgba(0, 0, 0, 0.3);
    content: "";
    height: 150px;
    left: 0;
    margin: auto;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -43px;
    transform: scale(1.5);
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease-in-out;
    width: 150px;
}

.toxiform .update-value.active::after {
    opacity: 1;
    transform: scale(1);
}

#zawartosc,
#trzezwosc {
    font-size: 32px;
}

.alert {
    position: relative;
}

.alert p {
    font-size: 13px;
}

.alert i {
    color: #D9534F;
    display: block;
    font-size: 42px;
    height: 42px;
    margin: 0 auto 10px;
    width: 42px;
}

.cta {
    margin: 30px 0;
}

.gallery {
    margin: 30px 0;
    text-align: center;
}

.gallery .item {
    margin-bottom: 20px;
}

.gallery img {
    cursor: pointer;
    margin: 0 auto;
}

.gallery button {
    margin-top: 15px;
    margin-bottom: 15px;
}

.gallery .modal-header {
    border-width: 0;
    padding-bottom: 5px;
    padding-top: 20px;
}

.gallery .modal-header .modal-title {
    font-size: 24px;
}

.gallery .modal-header .modal-title span {
    color: #777;
    display: block;
    font-size: 18px;
}

.gallery .modal-header .close {
    background-color: #222222;
    border-radius: 50%;
    color: #ffffff;
    height: 30px;
    opacity: 1;
    text-shadow: none;
    width: 30px;
}

.gallery .modal-body ul {
    list-style-image: none;
    list-style-position: inside;
    list-style-type: square;
    margin: 20px 0;
    padding: 0;
}

.gallery .modal-body p {
    text-align: left;
}

.gallery .modal-footer button {
    margin: 0;
}

.embed-responsive {
    margin: 30px 0;
}

.table-custom {
    border: 1px solid #ddd;
    margin-bottom: 30px;
}

.table-custom>thead {
    display: none;
}

.table-custom>tbody>tr>th,
.table-custom>tbody>tr>td {
    display: block;
    text-align: center;
    vertical-align: middle;
}

.table-custom>tbody>tr>td:nth-child(2n+1) {
    background-color: #ddd;
}

.table-custom>thead>tr>th,
.table-custom>tbody>tr>th {
    background-color: #47b086;
    border-bottom: 4px solid #ccc;
    color: #fff;
    font-family: "Oswald";
    font-size: 18px;
    font-weight: normal;
    padding: 10px;
    text-align: center;
}

.table-custom>tbody>tr>td::before {
    content: attr(data-th);
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

dl {
    border-radius: 2px;
    border: 1px solid #47B086;
    margin-bottom: 20px;
    padding: 20px 30px;
}

dd {
    margin-bottom: 15px;
}

figure {
    border-radius: 2px;
    margin-top: 15px;
    text-align: center;
}

figure .icon-item {
    background-color: #47b086;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 15px;
    padding: 40px;
}

figure .icon-item img {
    height: 96px;
}

figure figcaption {
    text-align: left;
}

.partners figure {
    border: 1px solid #ccc;
    padding: 5px;
}

.partners figure a {
    display: block;
    padding: 10px 20px;
    text-align: center;
}

.partners figure img {
    display: inline-block;
}

.partners figure figcaption {
    background-color: #ddd;
    font-size: 13px;
    padding: 10px;
    text-align: center;
}

.manufacturers {
    margin: 0 0 40px;
}

.manufacturers>div {
    margin: 10px 0;
    height: 48px;
}

.manufacturers img {
    max-height: 100%;
}

.store-logo {
    margin-top: 60px;
}

@media (min-width: 768px) {
    .toxiform .check-button-desktop {
        display: block;
    }
    .toxiform .check-button-mobile {
        display: none;
    }
    .alert p {
        padding-left: 80px;
    }
    .alert i {
        bottom: 0;
        left: 25px;
        margin: auto 0;
        position: absolute;
        top: 0;
    }
    .table-custom>thead {
        display: table-header-group;
    }
    .table-custom>tbody>tr>th,
    .table-custom>tbody>tr>td {
        display: table-cell;
    }
    .table-custom>tbody>tr>td::before {
        display: none;
    }
    .table-custom>tbody>tr>th {
        background-color: transparent;
        color: #333;
        font-family: inherit;
        font-size: 14px;
        font-weight: bold;
        padding: 10px;
        text-align: left;
    }
    .table-custom>tbody>tr>th {
        border-bottom-width: 0;
    }
    .table-custom>tbody>tr:nth-child(2n+2) {
        background-color: #ddd;
    }
    .table-custom>tbody>tr>td:nth-child(2n+1) {
        background-color: rgba(0, 0, 0, 0);
    }
    .partners figure figcaption {
        text-align: left;
    }
    .manufacturers>div {
        height: auto;
    }
    footer {
        padding: 25px 0;
    }
    footer h3 {
        margin: 0;
    }
    footer span.copyright {
        margin-top: 20px;
    }
}


/*End Main*/